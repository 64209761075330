.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.fireshield-sider {
  min-width: 220px !important;
  max-width: 220px !important;
}

.ant-menu-item-selected {
  background-color: #ed1c24 !important;
}

.ant-tabs-ink-bar {
  background-color: #ed1c24 !important;
}

.ant-tabs-tab-active {
  color: #ed1c24 !important;
}

.ant-tabs-tab:hover {
  color: #e64349 !important;
}

.ant-pagination-item-active {
  border-color: #e64349 !important;
  a {
    color: #e64349 !important;
  }
}

.ant-pagination-item:hover {
  border-color: #e64349 !important;
  a {
    color: #e64349 !important;
  }
}

.ant-pagination-prev:hover {
  a {
    border-color: #e64349 !important;
    color: #e64349 !important;
  }
}

.ant-pagination-next:hover {
  a {
    border-color: #e64349 !important;
    color: #e64349 !important;
  }
}

.ant-btn-primary {
  background-color: #e64349 !important;
  border-color: #e64349 !important;
}

.ant-btn:hover {
  border-color: #e64349 !important;
  color: #e64349 !important;
}

.ant-input:focus {
  border-color: #e64349 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(230, 67, 73, 0.2) !important;
  box-shadow: 0 0 0 2px rgba(230, 67, 73, 0.2) !important;
}

.logo-container {
  height: 6vh;
  //background: rgba(255, 255, 255, 0.2);
  margin: 0px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  .logo-icon {
    height: 100%;
    user-select: none;
  }
}
.logo-container-collapsed {
  //width: 5vw !important;
  height: 2vh !important;
}

.site-layout .site-layout-background {
  background: #fff;
}

.link-color {
  color: #fff !important;
}
