.login-page-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20% !important;
  .login-form {
    width: 100% !important;
  }
  .login-page-icon-container {
    display: flex;
    justify-content: center;
    padding: 1em;
    background-color: #ffffff !important;
    width: 100%;
    .login-page-icon {
      width: 100%;
      user-select: none;
    }
  }
}

.ant-form-item-explain {
  color: #ffffff !important;
}

.ant-btn-primary:hover {
  color: #fff !important;
}

.ant-input:focus {
  border-color: #e64349 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(230, 67, 73, 0.2) !important;
  box-shadow: 0 0 0 2px rgba(230, 67, 73, 0.2) !important;
}

.app-main {
  background-image: url("../../static/bg.jpg");
}

.question-label {
  label {
    color: #ffffff !important;
    font-size: 1.1rem !important;
  }
  label::before {
    color: #ffffff !important;
  }
}
