.ant-progress-text {
  color: #ffffff !important;
}

#unity-footer {
  position: absolute;
  float: right;
  width: 1280px;
  height: 58px;
  background-color: white;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
}

#unity-fullscreen-button {
  float: right;
  width: 38px;
  height: 38px;
  margin-top: 10px;
  margin-right: 10px;
  background: url("../../static/fullscreen-button.png") no-repeat center;
}
#unity-build-title {
  //float: right;
  margin-right: 20px;
  margin-top: 10px;
  line-height: 38px;
  font-family: arial;
  font-size: 18px;
}
